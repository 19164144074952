import React from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe3 from "components/SectionSubscribe2/SectionSubscribe3";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { PostDataType } from "data/types";

import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";


// DEMO DATA
const POSTS = DEMO_POSTS;

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
//

const BlogPage: React.FC = () => {

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };



  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Way Find Trips</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
      <h1 className="font-semibold text-xl my-2 md:text-3xl">📚 Popular Blogs</h1>

          <SectionMagazine5 posts={MAGAZINE1_POSTS} />
    
        </div>

        {/* === SECTION 1 === */}
        {/* <SectionAds /> */}
        <div className="flex mt-10 gap-4 px-4 py-2 flex-wrap cursor-pointer">
            <h1 className="bg-[#FFE68D] px-4 py-2">Travel Vlogger Blogs</h1>
            <h1 className="px-4 py-2 hover:bg-slate-100">Team Blogs</h1>
            <h1 className="px-4 py-2 hover:bg-slate-100">Trip Blogs</h1>
            <h1 className="px-4 py-2 hover:bg-slate-100">Others</h1>
          </div>
        <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
          <div className="container pb-24">
            <h2 className="text-3xl font-semibold">Collection of blogs by travel vloggers <br></br> around the world 🌎
              <br></br><span className="text-sm font-normal">The below blogs where sponsored by wayfindtrips</span></h2>
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
              {/*  */}
              {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
              {/*  */}
            </div>
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
              {/*  */}
              {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
              {/*  */}
            </div>
          </div>
        </div>

        {/* === SECTION 8 === */}
        {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}
        {/* === SECTION 1 === */}
        <div className="hover:bg-slate-50 hover:font-medium my-20 rounded-2xl py-2 w-full text-center cursor-pointer">Read more</div>
        <SectionSubscribe3 className="pb-16" />
      </div>
    </div>
  );
};

export default BlogPage;