import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { TaxonomyType } from "data/types";
import SectionGridCategoryBox1 from "containers/DestinationPage/SectionGridCategoryBox1";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor"
import SectionClientSay1 from "containers/DestinationPage/SectionClientSay1";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import SectionGridFeatureProperty1 from "containers/PackagePage/SectionGridFeatureProperty1";
import SectionSubscribe from "components/SectionSubscribe2/SectionSubscribe";

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Location Name",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PackagePage() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
        <div className="relative py-16 px-16">
          <SectionGridFeatureProperty1 />
        </div>

      {/* SECTION 1 */}
        <div className="relative py-16 px-16">
         <h1 className="text-4xl font-semibold">Testimonials & Gallery</h1>
            <div className="grid grid-cols-12 gap-6 mt-11">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
                <CardCategory6 taxonomy={DEMO_CATS_2[0]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 flex">
                <CardCategory6 taxonomy={DEMO_CATS_2[4]} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 grid grid-rows-2 gap-6">
                <CardCategory6 taxonomy={DEMO_CATS_2[3]} />
                <CardCategory6 taxonomy={DEMO_CATS_2[1]} />
            </div>
            </div>
        </div>
        {/* SECTION 1 */}
        <div className="relative py-16 px-16">
            <SectionSliderNewCategories
                heading="Discover Places in Andaman"
                subHeading="Explore houses based on 10 types of stays"
                categoryCardType="card5"
                itemPerRow={5}
                uniqueClassName="PageHome2_s2"
            />
        </div>
        <div className="relative py-16 px-16">
            <SectionSubscribe />
        </div>

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */} 
        

        

        {/* SECTION2 */}
        

        {/* SECTION */}
        

        {/* SECTION */}
        
      </div>
    </div>
  );
}

export default PackagePage;
