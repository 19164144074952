import React, { useState } from 'react'

const FAQData =[
  {
      id:1,
      question: 'What is Webflow and why is it the best website builder?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:2,
      question: 'What is your favorite template from BRIX Templates?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:3,
      question: 'What is your favorite template from BRIX Templates?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:4,
      question: 'How do you clone a template from the Showcase?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:5,
      question: 'How do you clone a template from the Showcase?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:6,
      question: 'What is Webflow and why is it the best website builder?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:7,
      question: 'Why is BRIX Templates the best Webflow agency?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:8,
      question: 'Why is BRIX Templates the best Webflow agency?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:9,
      question: 'When was Webflow officially launched?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:10,
      question: 'When was Webflow officially launched?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:11,
      question: 'How do you integrate Jetboost with Webflow?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },
  {
      id:12,
      question: 'How do you integrate Jetboost with Webflow?',
      answer: 'Vitae congue eu consequat ac felis placerat vestibulum lectus mauris ultrices. Cursus sit amet dictum sit amet justo donec enim diam porttitor lacus luctus accumsan tortor posuere.'
  },

]

function FAQPage() {

  const [data,setData] = useState(FAQData)
  const [show,setShow] = useState(false)
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='not-italic font-bold text-5xl text-[#303A42]'>FAQs</div>
      <div className='xl:grid xl:grid-cols-2 md:grid md:grid-cols-2 gap-11 mt-11'>
        {
          data.map((elem)=> {
            return (
              <div>
                <div className='w-[290px] xl:w-[550px] xl:h-[24px] md:w-[325px] not-italic font-semibold xl:text-lg md:text-lg hover:text-[#F19838] text-[#303A42] cursor-pointer'>{elem.question} <span onClick={() => setShow(!show)} className='ml-5'>{show? "—":"+"}</span></div>
                {show && <div className='w-[285px] xl:w-[500px] h-[75px] md:w-[295px] md:h-[75px] not-italic font-normal text-base mt-5 text-[#303A42]'>{elem.answer}</div>}
                <hr className='xl:mt-5 md:mt-[5rem] mt-[5rem]'/>
              </div>
            ) 
          })
        }
      </div>
    </div>
  )
}

export default FAQPage 