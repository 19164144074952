import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating1 from "components/StartRating/StartRating1";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import Day from "images/Day.png";
import Night from "images/Night.png";
import Meal from "images/Meal.png";
import Activity from "images/Activity.png";

export interface PropertyCardHProps {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardH1: FC<PropertyCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          href={""}
        />

        {saleOff && (
          <SaleOffBadge className="absolute left-5 top-5 bg-[#E53945]" />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            {/*<i className="las la-bed text-lg"></i>*/}
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            6 guests
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img src={Day} alt="" className="las la-bath text-lg"></img>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            12 Days
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img src={Night} alt="" className="las la-bath text-lg"></img>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            11 Nights
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            {/*<i className="las la-bed text-lg"></i>*/}
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            4 Hotels
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img src={Meal} alt="" className="las la-bath text-lg"></img>  
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            Meals
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <img src={Activity} alt="" className="las la-bath text-lg"></img>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            20+ Activities
          </span>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <h1 className="text-xl font-semibold">Title of the Package</h1>
            <StartRating1 reviewCount={reviewCount} point={reviewStart} />
          </div>
          <div className="flex items-center space-x-2">
            {/*isAds && <Badge name="ADS" color="green" />*/}
            <h2 className="text-sm font-medium capitalize">
              <span className="line-clamp-2 text-[#6B7280]">Package Description </span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
          <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">Category</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">category</span>
                </div>
              }
              color="yellow"
            />
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${price},000`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <Link to={""} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {/*<BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
       />*/}
    </div>
  );
};

export default PropertyCardH1;
